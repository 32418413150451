import React, { useEffect, useState } from 'react';
import TenderTracker from './TenderTracker';
import NotFound from './NotFound';
import { FBTCustomReports as FBTCustomReportsEnum } from '../../models/enums';
import { useLocation } from 'react-router';
import { useStore } from '../../models/ProvideModel';
import { observer, useObserver } from 'mobx-react-lite';
import { Skeleton } from '@mantine/core';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import noDataIcon from '../../assets/images/nodata.png';

const RenderTheReport = ({ reportId }) => {
    return (
        <>
            {reportId === FBTCustomReportsEnum.TENDER_TRACKER && <TenderTracker />}
            {!reportId && <NotFound />}
        </>
    );
};

const FBTCustomReports = () => {
    const store = useStore();
    const { search } = useLocation();
    const [reportName, setReportName] = useState<string | null>()
    const reportIdFromURL = new URLSearchParams(search).get('reportId');
    const [loading, setLoading] = useState<boolean>(false);
    // if(reportIdFromURL) {push(`${pathname}?reportId=${reportIdFromURL}`);}
    const [intermediateMessage, setIntermediateMessage] = React.useState('');
    const [validReportUser, setValidReportUser] = React.useState(false);

    useEffect(() => {
        const prepareData = async () => {
            setLoading(prev => !prev);
            setIntermediateMessage('Fetching permissions ...')
            try {
                if (store.userReportDetails.fbtReportsDetails.length === 0) {
                    await store.userReportDetails.getUserReportDetails();
                }  
                if(store.userReportDetails.fbtReportsDetails.length && reportIdFromURL) {
                    setIntermediateMessage('Attempting to fetch report ...');
                    const reportIdFromURL = new URLSearchParams(search).get('reportId');
                    const reports = store.userReportDetails.fbtReportsDetails;
                    reports.forEach(report => {
                        if (report.embedConfig?.reportId === reportIdFromURL) {
                            setValidReportUser(true);
                            const reportName = store.userReportDetails.getFbtCustomReportName(reportIdFromURL || "Report")
                            setReportName(reportName);
                        }
                    })
                    setLoading(prev => !prev);
                } else if(!reportIdFromURL) {
                    setValidReportUser(false);
                    setLoading(prev => !prev);
                }
            } catch(e) {
                console.error('prepareData error', e)
                setLoading(prev => !prev);
            } 
        }
        prepareData();
    }, [])

    return useObserver(() =>
        <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
            <div style={{ color: '#2F3E6C', fontSize: '1.7em', fontWeight: 700 , margin: '0.5em'}}>{loading ? <Skeleton height={40} width={150}/> : !reportIdFromURL ? null : reportName}</div>
            { (!loading && !validReportUser || (!reportIdFromURL))  ? //if not loading and user is a valid user
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    margin: "auto",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.29,
                    letterSpacing: "normal",
                    flexFlow: "column"
                }}><img src={noDataIcon} alt="nodata" /><div>
                        <p>
                            Oops! Something went wrong while getting the report for you..
                        </p>
                    </div> </div> : loading ? // if loading
                    <LoadingSpinner message={intermediateMessage}/> : // else render the report
                     <RenderTheReport reportId={reportIdFromURL} />}
        </div>
    )
}

export default observer(FBTCustomReports);