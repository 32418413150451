import React, { RefObject, memo, useMemo, MemoExoticComponent, FunctionComponent, useState, } from 'react';
import { Tabs } from '@mantine/core';
import { IChan } from 'csp-with-ts';
import { ITicket } from '../../../models/Ticket';
import useStyles from "./TabsComponentStyles";
import TicketDetails, { ITicketDetails } from '../tabItems/details/Details';
import TicketTasks, { ITicketTasks } from '../tabItems/tasks/Tasks';
import Attachment, { IAttachment, IFile } from '../tabItems/attachments/Attachment';
import CompletedTasks from '../tabItems/completedTasks/CompletedTasks'
import { IconPaperclip, IconFile } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { compose, toArray, transduce, filter, map } from 'transducist';

interface ITabsComponent {
  filesList: IFile[];
  usersMap: { [K: string]: string; };
  openRef: RefObject<() => void>;
  loading: boolean;
  ticket: ITicket;
  coreFields: { [K: string]: any; };
  customerId: string;
  userId: string;
  projectId: string;
  lg?: boolean;
  onClose?: () => void;
  taskIdValue?: string;
  ch: IChan;
  refreshTicket: (id: string) => Promise<any>;
}



const tabsConfig = [
  { id: "details", name: "Details", isVisible: true, default: true, component: TicketDetails, icon: IconFile, count: 0 },
  { id: "tasks", name: "Tasks", isVisible: true, default: false, component: TicketTasks, icon: IconFile, count: 0 },
  { id: "attachments", name: "Attachments", isVisible: true, default: false, component: Attachment, icon: IconPaperclip, count: 0 },
  { id: "completed", name: "Completed Tasks", isVisible: true, default: false, component: CompletedTasks, icon: IconPaperclip, count: 0 },
]

const TabsComponent = ({ ...rest }: ITabsComponent) => {
  const classes = useStyles()
  const visibleTabsConfig = useMemo(() =>
    transduce(
      tabsConfig,
      compose(
        map(({ id, isVisible, count, ...rst }) => ({
          id,
          count: id === "attachments" ? rest.filesList.length : count,
          isVisible: id === "completed" && rest.ticket.ticketDefinition.isAdhoc ? false : isVisible,
          ...rst
        })),
        filter(({ isVisible }) => isVisible)
      ),
      toArray<any>()
    ), [rest.filesList]);
  const [activeTab, setActiveTab] = useState<string | null>(() => tabsConfig[0].id);
  return (
    <div style={{ display: "flex", height: "100%", overflow: 'hidden' }}>
      <Tabs variant="default"
        value={activeTab}
        onTabChange={setActiveTab}
        style={{ display: 'flex', flexDirection: 'column', height: '100%', width: "100%" }}
        styles={{
          root: { display: "flex", flexDirection: "column", height: "100%", width: "100%", flexBasis: "auto" },
          panel: { display: "flex", flexBasis: "auto", height: "100%", overflow: "hidden" }

        }}
      >
        <Tabs.List
        >
          {
            visibleTabsConfig.map(tab => {
              if (rest.ticket.ticketDefinition.isAdhoc) {
                if (tab.id !== 'tasks') { //to hide 'tasks' tab for ad-hoc type ticket
                  return (
                  <Tabs.Tab key={tab.id} value={tab.id} className={classes.tab} style={{ fontWeight: (activeTab == tab.id) ? 600 : 400 }}>
                    {tab.name} {tab.count && tab.id === "attachments" ? ` (${tab.count})` : null}
                  </Tabs.Tab>
                  )
                }
              } else {
                return (
                <Tabs.Tab key={tab.id} value={tab.id} className={classes.tab} style={{ fontWeight: (activeTab == tab.id) ? 600 : 400 }}>
                  {tab.name} {tab.count && tab.id === "attachments" ? ` (${tab.count})` : null}
                </Tabs.Tab>
                )
              }
            }
            )
          }
        </Tabs.List>
        {
          visibleTabsConfig.map(tab => {
            const Component: FunctionComponent<ITicketDetails | ITicketTasks | IAttachment> = tab.component
            return (
              <Tabs.Panel
                key={tab.id}
                value={tab.id}
              >
                <Component activeTab={activeTab} {...rest} />
              </Tabs.Panel>
            )
          })
        }
      </Tabs>
    </div>
  )
}

export default observer(TabsComponent);
